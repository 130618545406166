import React, { useState } from 'react';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';

import SelectSimple from '../Selects/SelectSimple';

const FilterOrderByEvents = (props) => {
	const history = useHistory();
	const query = QueryString.parse(history.location.search);

	const attr=`ordenar`;
	const o = query[attr];
	const [ option, setOption ] = useState(o ? o : 1)
  if(props.input){
    return(
      <InputOrder {...{
        option,
        setOption,
        attr,
        query,
        history,
        parentProps:{...props}
      }} />
    )
  }
	return(
		<div className="row">
			<div className="col-12 mb-3 px-1" >
				<div className="w-100 px-3 py-2 bg-i-gray border">
					<div className="row justify-content-end">
						<div className="col-12 col-md-4">
							<InputOrder {...{
                option,
                setOption,
                attr,
                query,
                history,
                parentProps:{...props}
              }}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FilterOrderByEvents;

const InputOrder =  (props) => {
  const history = useHistory();
  return(
    <SelectSimple
      type="simple"
      initValue={props.option}
      data={[
        {
          value:'order_by_higher_points',
          label:'Mayor Puntaje'
        },
        {
          value:'order_by_max_reviews',
          label:'Reviews mayor a menor'
        },
        {
          value:'order_by_min_name',
          label:'A - Z'
        },
      ]}
      className={`form-select ${props.parentProps.classNameInput}`}
      name={props.attr}
      placeholder="Ordenar por..."
      runChange={(e) => {
        props.query[props.attr] = e.target.value;
        props.setOption(e.target.value)
        history.push({
          pathname: history.location.pathname,
          search:QueryString.stringify(props.query),
          state: { [props.attr]: e.target.value }
        })
      }}
    />
  )
}