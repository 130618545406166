import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { DateTime } from "react-datetime-bootstrap";
import Api from "../Services/Api";

const FormEvents = (props) => {
  const { event, loadList } = props;
  const [data, setData] = useState({});
  const [form, setForm] = useState({});
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [isChecked, setIsChecked] = useState({
    nombre: false,
    "nombre-Oblig": false,
    rut: false,
    "rut-Oblig": false,
    fecha_Nac: false,
    "fecha_Nac-Oblig": false,
  });
  const [eventConfig, setEventConfig] = useState({});
  const [oldImage, setOldImage] = useState([]);
  const [oldLinks, setOldLinks] = useState([]);

  const getEventData = async (event) => {
    let res = await Api.getParamsEventsAdmin({ id: event.id });
    const checked = {
      nombre: res.nombre === 1 || res.nombre === 2 ? true : false,
      "nombre-Oblig": res.nombre == 2 ? true : false,
      rut: res.rut === 1 || res.rut === 2 ? true : false,
      "rut-Oblig": res.rut === 2 ? true : false,
      fecha_Nac:
        res.fecha_Nacimiento === 1 || res.fecha_Nacimiento === 2 ? true : false,
      "fecha_Nac-Oblig": res.fecha_Nacimiento === 2 ? true : false,
    };
    setIsChecked(checked);
    setEventConfig(res);
  };

  useEffect(() => {
    if (event != null) {
      setData(event);
      setForm(event);
      getEventData(event);
      setOldImage(event.images);
      setOldLinks(event.links);
    }
  }, [props.event]);

  const selectActive = { selected: "none" };

  const handleOnChnage = (e, info) => {
    const name = e.target ? e.target.name : e;
    const value = e.target ? e.target.value : info.value ? info.value : info;
    setForm({
      ...data,
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = {};
    let res = getCheked();
    form["isChecked"] = res;
    form["photo"] = files;
    form["links"] = links;
    form["oldImage"] = oldImage;
    form["oldLinks"] = oldLinks;
    if (form.id) {
      response = await Api.adminEventsUpdate(form.id, form);
      alert("Actualizado de forma exitosa!");
    } else {
      response = await Api.adminEventsCreate(form);
      alert("Creado de forma exitosa!");
    }
    loadList();
  };

  const getCheked = () => {
    let res = {
      nombre: 0,
      rut: 0,
      fecha_Nacimiento: 0,
    };
    if (isChecked.nombre) {
      res.nombre = 1;
      if (isChecked["nombre-Oblig"]) {
        res.nombre = 2;
      }
    }
    if (isChecked.rut) {
      res.rut = 1;
      if (isChecked["rut-Oblig"]) {
        res.rut = 2;
      }
    }
    if (isChecked.fecha_Nac) {
      res.fecha_Nacimiento = 1;
      if (isChecked["fecha_Nac-Oblig"]) {
        res.fecha_Nacimiento = 2;
      }
    }
    return res;
  };

  const handleCheckboxChange = (e) => {
    setIsChecked({
      ...isChecked,
      [e.target.name]: e.target.checked,
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newFiles = [];

    // Crear un array de promesas para manejar la conversión a base64
    const filePromises = selectedFiles.map((file) =>
      convertToBase64(file).then((base64) => {
        newFiles.push({
          file: base64,
          name: file.name,
        });
      })
    );

    // Esperar a que todas las conversiones se completen
    Promise.all(filePromises).then(() => {
      setFiles((prev) => [...prev, ...newFiles]);
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error al convertir el archivo:", error);
        reject(error);
      };
    });
  };

  const handleUrlChange = (index, value) => {
    const updatedUrls = [...links];
    updatedUrls[index] = value;
    setLinks(updatedUrls);
  };

  const handleOldImage = (index) => {
    const newImages = oldImage.filter((_, i) => i !== index);
    setOldImage(newImages);
  };

  const handleOldImgLink = (index, value) => {
    const newLinks = [...oldImage];
    newLinks[index].link = value;
    setOldImage(newLinks);
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <FormGroup>
        <Label for="eventName">Nombre</Label>
        <Input
          required
          type="text"
          id="eventName"
          name="name"
          placeholder="nombre del evento.."
          defaultValue={data.name}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="eventStart">Inicio</Label>
            <DateTime
              required
              id="eventStart"
              name="start"
              pickerOptions={{
                format: "YYYY-MM-DD HH:mm",
              }}
              placeholder="fecha de inicio.."
              value={data.start}
              onChange={(e, date) => handleOnChnage("start", date)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="eventEnd">Fin</Label>
            <DateTime
              required
              id="eventEnd"
              name="end"
              pickerOptions={{
                format: "YYYY-MM-DD HH:mm",
              }}
              placeholder="fecha de fin.."
              value={data.end}
              onChange={(e, date) => handleOnChnage("end", date)}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="eventActive">Estado</Label>
        <Input
          required
          type="select"
          id="eventActive"
          name="active"
          value={form.active}
          onChange={(e) => handleOnChnage(e)}
        >
          <option value="">Selecciona el estado</option>
          <option value={1}>Activo</option>
          <option value={0}>Inactivo</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="eventType">Tipo</Label>
        <Input
          required
          type="select"
          id="eventType"
          name="type"
          value={form.type}
          onChange={(e) => handleOnChnage(e)}
        >
          <option value={1}>Zonas</option>
          <option value={2}>Categorías</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="eventDescription">Descripción(Acepta tags html)</Label>
        <Input
          type="textarea"
          id="eventDescription"
          name="description"
          defaultValue={data.description}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>

      <Label for="eventDescription">Campos personalizados</Label>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          justifyItems: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <label
          style={{
            marginLeft: "10px",
            width: "140px",
          }}
        >
          Nombre
        </label>
        <input
          name="nombre"
          type="checkbox"
          checked={isChecked.nombre}
          onChange={handleCheckboxChange}
          style={{ marginLeft: "10px", marginTop: "-10px" }}
        />

        <label>
          <input
            name="nombre-Oblig"
            type="checkbox"
            checked={isChecked["nombre-Oblig"]}
            onChange={handleCheckboxChange}
            style={{ marginLeft: "10px" }}
            disabled={!isChecked["nombre"]}
          />
          <span className="ml-1">Requerido</span>
        </label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <label
          style={{
            marginLeft: "10px",
            width: "140px",
          }}
        >
          Rut
        </label>
        <input
          name="rut"
          type="checkbox"
          checked={isChecked.rut}
          onChange={handleCheckboxChange}
          style={{ marginLeft: "10px", marginTop: "-10px" }}
        />

        <label>
          <input
            name="rut-Oblig"
            type="checkbox"
            checked={isChecked["rut-Oblig"]}
            onChange={handleCheckboxChange}
            style={{ marginLeft: "10px" }}
            disabled={!isChecked["rut"]}
          />
          <span className="ml-1">Requerido</span>
        </label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <label
          style={{
            marginLeft: "10px",
            width: "140px",
          }}
        >
          Fecha de Nacimiento
        </label>
        <input
          name="fecha_Nac"
          type="checkbox"
          checked={isChecked.fecha_Nac}
          onChange={handleCheckboxChange}
          style={{ marginLeft: "10px", marginTop: "-10px" }}
        />

        <label>
          <input
            name="fecha_Nac-Oblig"
            type="checkbox"
            checked={isChecked["fecha_Nac-Oblig"]}
            onChange={handleCheckboxChange}
            style={{ marginLeft: "10px" }}
            disabled={!isChecked["fecha_Nac"]}
          />
          <span className="ml-1">Requerido</span>
        </label>
      </div>
      <FormGroup style={{ marginTop: "7px" }}>
        <Label for="eventName">Titulo App</Label>
        <Input
          required
          type="text"
          id="eventName"
          name="title_app"
          placeholder="Nombre del evento para la app..."
          defaultValue={data.title_app}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>
      <FormGroup style={{ marginTop: "7px" }}>
        <Label for="eventName">Description App</Label>
        <Input
          required
          type="text"
          id="eventName"
          name="description_app"
          placeholder="Descripcion del evento.."
          defaultValue={data.description_app}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>
      <FormGroup style={{ marginTop: "7px" }}>
        <Label for="eventName">Placeholder App</Label>
        <Input
          required
          type="text"
          id="eventName"
          name="place_holder_app"
          placeholder="Placeholder del evento.."
          defaultValue={data.place_holder_app}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>
      <FormGroup style={{ marginTop: "7px" }}>
        <Label for="eventName">Titulo Filtros App</Label>
        <Input
          required
          type="text"
          id="eventName"
          name="title_organization_app"
          placeholder="Titulo filtros app.."
          defaultValue={data.title_organization_app}
          onChange={(e) => handleOnChnage(e)}
        />
      </FormGroup>
      {form.id && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            gap: "10px",
          }}
        >
          <img
            src="/information.png"
            alt="Information"
            style={{ width: "20px", height: "20px" }}
          />
          <p style={{ color: "#ffc800" }}>
            Si no deseas realizar cambios en estos campos, por favor déjalos
            vacíos.
          </p>
        </div>
      )}
      {oldImage.length > 0 && (
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            width: "100%",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {oldImage.map((img, index) => (
            <div
              key={img.id || index}
              style={{
                position: "relative",
                width: "220px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <img
                  src={img.image}
                  alt={img.title}
                  style={{
                    width: "200px",
                    height: "100px",
                    borderRadius: "10px",
                  }}
                />
                <input
                  style={{
                    width: "200px",
                    height: "30px",
                    borderRadius: "10px",
                    paddingLeft: "7px",
                    border: "1px solid gray",
                  }}
                  placeholder="Link de la redirección"
                  value={img.link}
                  onChange={(e) => handleOldImgLink(index, e.target.value)}
                />
              </div>
              <button
                type="button"
                onClick={() => handleOldImage(index)}
                style={{
                  borderRadius: "10px",
                  position: "absolute",
                  top: "5px",
                  right: "10px",
                }}
              >
                &#215;
              </button>
            </div>
          ))}
        </div>
      )}

      <div>
        <Label for="eventImage">Imagenes</Label>
        <input
          onChange={handleFileChange}
          style={{ marginLeft: "15px" }}
          type="file"
          multiple
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          marginTop: "10px",
          paddingBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        {files.map((file, index) => (
          <div key={index}>
            <img
              src={file.file}
              alt={file.name}
              style={{ width: "200px", height: "100px", borderRadius: "10px" }}
            />
            <p>{file.name}</p>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "18px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {files.map((_, index) => (
          <div
            key={index}
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <p>Link imagen {index + 1}</p>
            <input
              onChange={(e) => handleUrlChange(index, e.target.value)}
              style={{
                marginLeft: "15px",
                width: "50%",
                height: "35px",
                borderRadius: "10px",
                paddingLeft: "7px",
                border: "1px solid gray",
              }}
              type="text"
            />
          </div>
        ))}
      </div>
      <Button type="submit">{data.id ? "Actualizar" : "Crear"}</Button>
    </Form>
  );
};
export default FormEvents;
