import React, { useState,useRef,useEffect } from 'react';
import QueryString from 'query-string';
import { AutoComplete, InputGroup  } from 'rsuite';
import { useHistory } from "react-router-dom"
import Api from '../../Services/Api';

let doneTypingInterval = 500;
let typingTimer= setTimeout(()=>null,doneTypingInterval);

const MainAutoCompleteJude = (props) => {

	const history = useHistory();
  const ref = useRef(null);
  // const query = QueryString.parse(history.location.search);
	const [ widthInput, setWidthInput ]= useState({width:'auto'});
	const [ results, setResults ]= useState([]);
	const [ data, setData ] = useState({
		keywords:'',
		refres:false
	});
	const [ classActive, setClassActive ] = useState('');
	const { addClass } = props;
  useEffect(() => {
    setWidthInput({width:(ref.current ? `${ref.current.offsetWidth-2}px` : 'auto')});
  }, [ref.current]);
	const handleChange = (keywords) => {
		let current = [{
			label:keywords,
			value:keywords,
			loading:true
		}];
		setResults(current);
		setData({
			keywords
		})
	}
	const callbackDispatch = async () => {
		if ( data.keywords.length < 3 ) {
      //alert(1)
			let current = [{
				label:data.keywords,
				value:data.keywords,
				validation:true
			}];
			setResults(current);
			setData({
				...data,
				refres:!data.refres
			})
		}else{
      //alert(2)
      const response = await Api.serachUser({search:data.keywords})
      console.log('resultado',response)
      setResults([
				...response.data,
      ])
      // alert()
			//Actions.Search.AutoComplete(data.keywords,dispatch);
		}
	}
	const handleMobile = () => {
		setClassActive(classActive!=='' ? '' : 'active')
    if (classActive==='active'){
      handleSearchResuls()
    }
	}
  const handleSearchResuls = () => {
    // query['search']=data.keywords;
    if( data.keywords!== ''){
      history.push({
        pathname: '/tienda',
        search:QueryString.stringify({search:data.keywords}),
        state: { search: data.keywords }
      });
    }
  }
  return(
    <InputGroup inside style={{}} ref={ref} className="input-super-search">
      <AutoComplete
        className=""
        menuClassName="super-search-results"
        placeholder={props.placeholder}
        aria-label={props.placeholder}
        aria-describedby="basic-addon2"
        data={results}
        onChange={(value, event) =>{
          handleChange(value)
        }}
        filterBy={(value,item) => true}
        onKeyPress={()=>{
          clearTimeout(typingTimer);
        }}
        onKeyUp={(e)=>{
          clearTimeout(typingTimer);
          typingTimer = setTimeout(callbackDispatch, doneTypingInterval);
          if (e.key === 'Enter' || e.keyCode === 13) {
            handleSearchResuls()
          }
        }}
        onSelect={(item,event) =>{
          if (item.category_id) {
            history.push(`/categoria/${item.slug}`);
          }
          if (item.product_id) {
            history.push(`/producto/${item.slug}`);
          }
          setClassActive('')
        }}
        renderMenuItem={(label,item) => {
          console.log('item',item)
          const OnLoading = () => {
            return(
              <p className="text-center border-0 pt-3" style={widthInput}>
                <i class="fas fa-spinner fa-spin"></i>
                <br/>
                Buscando...
              </p>
            )
          }
          const NotFoundAuto = () => {
            return(
              <p className="text-center border-0 pt-3" style={widthInput}>
                Sin resultados para "{item.label}"
              </p>
            )
          }
          const OnValidation = () => {
            return(
              <p className="text-center border-0 pt-3" style={widthInput}>
                Ingresa más letras
              </p>
            )
          }
          const Items = () => {
            return(
              <div className="row border-bottom pb-1 text-small mx-0" onClick={()=>{history.push(`/perfil/${item.slug}`)}}>
                <div className="col-auto text-right">
                    {	item.avatar_url ?
                      <img className="img-fluid" src={item.avatar_url} alt="..." style={{maxWidth:'57px'}}/>
                      : <div className="py-3 px-3"/>
                    }
                </div>
                <div className="col-auto">
                  <small
                    className="fw-bold"
                  >
                  {item.name ? item.name : item.slug}
                  </small>
                  <br/>
                  <small className="text-muted">{item.sku}</small>
                  {/*<TagGroup>
                    <Tag>Categoría 1</Tag>
                    <Tag>Categoría 2</Tag>
                    <Tag>Categoría 3</Tag>
                  </TagGroup>*/}
                </div>
              </div>
            )
          }
          if (item.loading) {
            return <OnLoading/>
          }
          if (item.validation) {
            return <OnValidation/>
          }
          if (item.notFound) {
            return <NotFoundAuto/>
          }
          return <Items/>
        }}
      />
      <InputGroup.Button>
        <i class="fas fa-search"></i>
      </InputGroup.Button>
    </InputGroup>
  )
}

export default MainAutoCompleteJude