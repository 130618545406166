var API=null
//alert(process.env.REACT_APP_SET_SERVER)
if (process.env.REACT_APP_SET_SERVER === 'production'){
	API = "https://votaciones.thetop.cl/api/v1/public"
	//API = "https://staging-votaciones.thetop.cl/api/v1/public"
	//API = "https://thetop.moiworking.com/api/v1/public"
} else if (process.env.REACT_APP_SET_SERVER === 'staging') {
	API = "https://staging-api.thetop.cl/"
} else {
	// API = "http://192.168.1.92/theTopChile/api/v1/public"
	//API = "http://192.168.0.7/theTopChile/api/v1/public"
	// API = "http://192.168.1.87/theTopChile/api/v1/public"
	API =  "http://thetop.local/api/v1/public"
	//API = "http://localhost/theTopChile/api/v1/public"
}
export const BACK_TOKEN ='K76788sda8d787'
export const BACK_URL = API