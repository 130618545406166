import React, { Component, useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import Api from "../Services/Api";
import FormProcessing from "../Services/FormProcessing";

const FormProvaider = (props) => {
  const { provaider, loadList } = props;
  const [data, setData] = useState({});
  const [form, setForm] = useState({});
  const [isCheckedDineIn, setIsCheckedDineIn] = useState(false);
  const [isCheckedPickUp, setIsCheckedPickUp] = useState(false);
  const [isCheckedDelivery, setIsCheckedDelivery] = useState(false);
  const [imageData, setImageData] = useState({ file: "", name: "" });
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [oldImage, setOldImage] = useState([]);
  const [name_pide_aqui, setNamePideAqui] = useState("");

  useEffect(() => {
    const { provaider } = props;
    if (provaider != null) {
      setData(provaider);
      setForm(provaider);
      setIsCheckedDineIn(provaider.dine_in);
      setIsCheckedPickUp(provaider.pick_up);
      setIsCheckedDelivery(provaider.delivery);
      setNamePideAqui(provaider.name_pide_aqui);
      setUrl(provaider.pide_aqui_url);
    }
  }, [props.provaider]);
  const selectActive = { selected: "none" };

  const handleOnChnage = async (e, info) => {
    if (
      e.target &&
      e.target.type === "file" &&
      typeof e.target.files[0] !== "undefined"
    ) {
      const name = e.target.files[0].name;
      const promise = await FormProcessing.getBase64(e.target.files[0]);
      setForm({
        ...data,
        ...form,
        logo_provider: {
          file: promise.result,
          name,
        },
      });
    } else {
      const name = e.target ? e.target.name : e;
      const value = e.target ? e.target.value : info.value ? info.value : info;
      setForm({
        ...data,
        ...form,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = {};
    form["dine_in"] = isCheckedDineIn;
    form["pick_up"] = isCheckedPickUp;
    form["delivery"] = isCheckedDelivery;
    form["img_profile_app"] = imageData;
    form["pide_aqui_url"] = url;
    form["title_organization"] = title;
    form["name_pide_aqui"] = name_pide_aqui;
    if (form.id) {
      response = await Api.adminProvaidersUpdate(form.id, form);
      alert("Actualizado de forma exitosa!");
    } else {
      response = await Api.adminProvaidersCreate(form);
      alert("Creado de forma exitosa!");
    }
    loadList();
  };

  const handleCheckboxDineIn = (event) => {
    setIsCheckedDineIn(event.target.checked);
  };

  const handleCheckboxPickUp = (event) => {
    setIsCheckedPickUp(event.target.checked);
  };

  const handleCheckboxDelivery = (event) => {
    setIsCheckedDelivery(event.target.checked);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData({
          file: reader.result,
          name: file.name,
        });
      };

      reader.readAsDataURL(file);
    }
  };
  const handleOnChangeUrl = (e) => {
    setUrl(e.target.value);
  };
  const handleOnChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleNamePideAqui = (e) => {
    setNamePideAqui(e.target.value);
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="provaiderName">Nombre</Label>
            <Input
              required
              type="text"
              id="provaiderName"
              name="name"
              placeholder="nombre del porveedor.."
              defaultValue={data.name}
              onChange={(e) => handleOnChnage(e)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="provaiderSlug">Slug</Label>
            <Input
              required
              type="text"
              id="provaiderSlug"
              name="slung"
              placeholder="slug del porveedor.."
              defaultValue={data.slug}
              onChange={(e) => handleOnChnage(e)}
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="provaiderRegiones">Regiones</Label>
        <Input
          required
          type="select"
          id="provaiderRegiones"
          name="region_id"
          value={form.region_id}
          onChange={(e) => handleOnChnage(e)}
        >
          <option value="">Selecciona la región</option>
          {data.selects && data.selects.regions ? (
            data.selects.regions.map(function (elem, index) {
              return (
                <option value={elem.id} key={`regions-${index}`}>
                  {elem.name}
                </option>
              );
            })
          ) : (
            <option>Sin region</option>
          )}
        </Input>
      </FormGroup>
      <FormGroup>
        <Row form>
          <Col md={6}>
            <Label for="providersFile">Foto</Label>
            <FormGroup>
              <Input
                type="file"
                id="providersFile"
                name="logo_provider"
                onChange={(e) => handleOnChnage(e)}
              />
              <FormText color="muted">
                Recuerda subir las fotos con las medidas necesarias.
              </FormText>
            </FormGroup>
          </Col>
          <Col md={6}>
            {!form.logo_provider && data.logo_url ? (
              <img className="img-fluid" src={data.logo_url} style={{width:"100px", height:"100px"}} />
            ) : form.logo_provider ? (
              <img className="img-fluid" src={form.logo_provider.file}  style={{width:"100px", height:"100px"}} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </FormGroup>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{height:"100px"}}>
        <p>Imagen Perfil App</p>
        <input type="file" onChange={handleFileChange} />
        <p style={{ color: "gray", marginTop: "-0px" }}>
        Sube la imagen que aparecerá en la parte superior como la foto de perfil del restaurante.
        </p>
        </div>
        <div>
        {imageData.file && (
          <img
            src={imageData.file}
            alt={imageData.name}
            style={{ width: "100px", height: "100px" }}
          />
        )}
        {provaider.img_profile_app && imageData.file==="" && (
          <img
            src={provaider.img_profile_app}
            alt={provaider.img_profile_app}
            style={{ width: "100px", height: "100px" }}
          />
        )}
        {oldImage.length > 0 &&
          oldImage.map((elem, index) => {
                <img src={elem.images} alt={elem.name} width={100} height={100} />;
          })}
        </div>
      </div>
      <FormGroup>
        <Label for="provaiderDescription">Descripción(Acepta tags html)</Label>
        <Input
          type="textarea"
          id="provaiderDescription"
          name="description"
          defaultValue={data.description}
          onChange={(e) => handleOnChnage(e)}
          rows="8"
          cols="50"
        />
      </FormGroup>
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Label for="imgProfile">Titulo ver más</Label>
        <input
          style={{
            width: "100%",
            height: "35px",
            backgroundColor: "white",
            border: "1px solid #9D9BA0",
            borderRadius: "5px",
            padding: "5px",
          }}
          type="text"
          name="name_pide_aqui"
          placeholder="Titulo ver más"
          defaultValue={name_pide_aqui}
          onChange={(e) => handleNamePideAqui(e)}
        />
      </div>
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Label for="imgProfile">Ver más url</Label>
        <input
          style={{
            width: "100%",
            height: "35px",
            backgroundColor: "white",
            border: "1px solid #9D9BA0",
            borderRadius: "5px",
            padding: "5px",
          }}
          type="text"
          id="imgProfile"
          name="pide_aqui_url"
          placeholder="Url del boton ver más"
          defaultValue={url}
          onChange={handleOnChangeUrl}
        />
      </div>
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Label for="title">Titulo Organizacion</Label>
        <input
          style={{
            width: "100%",
            height: "35px",
            backgroundColor: "white",
            border: "1px solid #9D9BA0",
            borderRadius: "5px",
            padding: "5px",
          }}
          type="text"
          id="title"
          name="title_organization"
          placeholder="Titulo que sale antes del producto"
          defaultValue={data.title_organization}
          onChange={handleOnChangeTitle}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <p style={{ width: "120px" }}>Consumo en local</p>
          <input
            type="checkbox"
            name="consumoLocal"
            id="consumoLocal"
            onChange={handleCheckboxDineIn}
            checked={isCheckedDineIn}
          />
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <p style={{ width: "120px" }}>Retiro en local</p>
          <input
            type="checkbox"
            name="retiroLocal"
            id="retiroLocal"
            onChange={handleCheckboxPickUp}
            checked={isCheckedPickUp}
          />
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <p style={{ width: "120px" }}>Delivery</p>
          <input
            type="checkbox"
            name="delivery"
            id="delivery"
            onChange={handleCheckboxDelivery}
            checked={isCheckedDelivery}
          />
        </div>
      </div>
      <Button>{data.id ? "Actualizar" : "Crear"}</Button>
    </Form>
  );
};
export default FormProvaider;
