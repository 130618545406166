import React, {Component, useEffect, useState } from 'react';
import QueryString from 'query-string';
import { useHistory, Link } from 'react-router-dom';
import Api from '../Services/Api';
import { GetCategoryUser }from '../Services/Helpers';
import  { ReactComponent as Start } from  '../Layout/Assets/boton_estrella-01.svg'
import UserImg from '../Layout/Assets/user.png';
import Paginate from '../Layout/Components/Paginate'
import SearchUser from '../Components/Searchs/SearchUser'

const TodosLosJuez  = (props) => {
  const history = useHistory();
  const [data, setData] = useState({
    restaurants: false,
    pagination:null,
    loading:true,
  })
  const {restaurants, pagination, loading} = data
  useEffect(()=>{
     const run = async () => {
      let response = await Api.theTopJudge({})
      var pages = {
        last_page:response.last_page,
        current_page:response.current_page,
        per_page:response.per_page
      }
      setData({
        restaurants:response.data,
        pagination:pages,
        loading:false
      })
    }
    run()
  },[])

  useEffect(()=>{
    const run = async () =>{
      let query = QueryString.parse(history.location.search);
      const response = await Api.theTopJudge(query)
      var pages = {
        last_page:response.last_page,
        current_page:response.current_page,
        per_page:response.per_page
      }
      setData({
        restaurants:response.data,
        pagination:pages,
        loading:false
      })
    }
    run()
  },[history.location.search,props]);

  const loadList = async (page={}) => {
    let response = await Api.theTopJudge(page)
    var pages = {
      last_page:response.last_page,
      current_page:response.current_page,
      per_page:response.per_page
    }
    setData({
      restaurants:response.data,
      pagination:pages,
      loading:false
    })
  }
  return(
    <div className="row">
			<div className="bg-light col-12 py-5 mt-negative-4">
			<h3 className="text-center text-danger my-3 pt-4">
				THE TOP JUEZ
			</h3>
			<p className="text-center m-auto">
				Revisa aquí los Sibaritas mas destacados y sus votos.
				<br/>
				Recuerda que mientras mas votes puedes 
				<br/>
				subir de categoría y acceder a los beneficios.
				<br/>
				<Link
					to="/categorias"
					className="btn py-2 my-5 custom-btn-red custom-btn rounded-pill"
				>
					<GetCategoryUser/>
					Ver categorías
				</Link>
			</p>
      <SearchUser/>
			<div className="row px-2">
				<Judges1 {...{restaurants,loading}}/>
			</div>
			</div>
			<div
				className="col-12 d-flex justify-content-center fixed-bottom pb-5"
				style={{
					marginBottom: '5rem',
				}}
			>
				{
					pagination != null ?
					<Paginate 
						last_page={pagination.last_page}
						current_page={pagination.current_page} 
						per_page={pagination.per_page}
						loadList={loadList}
						classNamew="border border-bottom-0 bg-white px-5 pt-4"
					/>
					:null
				}
			</div>
			</div>
  )
}
export default TodosLosJuez;
export const Judges1 = (props) => {
  if(typeof props.restaurants === 'object') {
    if(props.restaurants.length==0){
      return (
        <div className="col-md-12 text-center h4">
          <h3>Sin usuarios</h3>
        </div>
      );
    }
    return (
      <>
      {props.restaurants.map(function(voter, i){
        return (
          <Link
            to={"/juez/"+voter.user.username} 
            className=" mb-3 col-md-6 col-lg-4 px-1" key={"r-"+(i+1)}
          >
            <div className="card shadow-sm bg-white rounded ">
              <div className="row no-gutters text-dark ">
                <div className="col-4 border-right p-1">
                  <img 
                    alt={"img-"}
                    className="card-img h-100"
                    src={voter.user.avatar_id != null ? voter.user.avatar_url : UserImg}
                    style={{
                      filter:'grayscale(100%) invert(0%) grayscale(66%)',
                    }}
                  />
                </div>
                <div className="col-8">
                  <div className="card-body">
                    <p className="card-text">
                      <GetCategoryUser countVoting={voter.count_voting}/>
                      <small>votos: {voter.count_voting}</small>
                      <br/>
                      <small>{voter.last_voting_at}</small>
                    </p>
                    <h5 className="card-title">
                      {
                        voter.user.full_name.length === 1 ?
                        voter.user.username :
                        voter.user.full_name
                      }
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )
      })}
      </>
    )
  }else{
    return (<>
    {	props.loading===true ?
      <div className="col-md-4 text-center h4">
        {/* <img alt="" className="img-fluid" src={chop}/> */}
        <Start height="35px" className="start-vote"/>
        <br/>
        Buscando participantes..
      </div>
      : <div className="col-md-12 text-center h4">
        <h3>Sin usuarios</h3>
      </div>
    }
    </>)
  }
}

export class TodosLosJuez1 extends Component {
	constructor(props) {
		super(props)
		this.state = {
			restaurants: false,
			pagination:null,
			loading:true,
		}
		this.loadList = this.loadList.bind(this)
	}
	componentDidMount() {
		window.scrollTo(0,0)
		this.loadList()
	}
	async loadList(page={}){
		let response = await Api.theTopJudge(page)
		var pages = {
			last_page:response.last_page,
			current_page:response.current_page,
			per_page:response.per_page
		}
		this.setState({
			restaurants:response.data,
			pagination:pages,
			loading:false
		})
	}
	render() {
		let {restaurants, pagination, loading} = this.state
		//let array = []
		const Judges = () => {
			if(typeof restaurants === 'object') {
				if(restaurants.length==0){
					return (
						<div className="col-md-12 text-center h4">
							<h3>Sin votaciones</h3>
						</div>
					);
				}
				return restaurants.map(function(voter, i){
					return (
						<Link
							to={"/juez/"+voter.user.username} 
							className=" mb-3 col-md-6 col-lg-4 px-1" key={"r-"+(i+1)}
						>
							<div className="card shadow-sm bg-white rounded ">
								<div className="row no-gutters text-dark ">
									<div className="col-4 border-right p-1">
										<img 
											alt={"img-"}
											className="card-img h-100"
											src={voter.user.avatar_id != null ? voter.user.avatar_url : UserImg}
											style={{
												filter:'grayscale(100%) invert(0%) grayscale(66%)',
											}}
										/>
									</div>
									<div className="col-8">
										<div className="card-body">
											<p className="card-text">
												<GetCategoryUser countVoting={voter.count_voting}/>
												<small>votos: {voter.count_voting}</small>
												<br/>
												<small>{voter.last_voting_at}</small>
											</p>
											<h5 className="card-title">
												{
													voter.user.full_name.length === 1 ?
													voter.user.username :
													voter.user.full_name
												}
											</h5>
										</div>
									</div>
								</div>
							</div>
						</Link>
					)
				},this)
			}else{
				return (<>
				{	loading===true ?
					<div className="col-md-4 text-center h4">
						{/* <img alt="" className="img-fluid" src={chop}/> */}
						<Start height="35px" className="start-vote"/>
						<br/>
						Buscando participantes..
					</div>
					: <div className="col-md-12 text-center h4">
						<h3>Sin votaciones</h3>
					</div>
				}
				</>)
			}
		}
		/*for (var i = 0; i < 21; i++) {
			array.push(
			<Link to={"/juez/1"} className=" mb-3 col-md-6 col-lg-4 px-1" key={"r-"+(i+1)}>
				<div className="card shadow-sm bg-white rounded ">
					<div className="row no-gutters text-dark ">
						<div className="col-4 border-right p-1">
							<img alt={"img-"} className="card-img h-100" src={UserImg} style={{
								filter:'grayscale(100%) invert(0%) grayscale(66%)',
							}}/>
						</div>
						<div className="col-8">
							<div className="card-body">
								<p className="card-text">
									<CategoriaIcon className="medalla gold"/>
									<small>votos: {4}</small>
								</p>
								<h5 className="card-title">
									{'Nombre de usuario'}
								</h5>
							</div>
						</div>
					</div>
				</div>
			</Link>
			)
		}*/
		return (
			<div className="row">
			<div className="bg-light col-12 py-5 mt-negative-4">
			<h3 className="text-center text-danger my-3 pt-4">
				THE TOP JUEZ
			</h3>
			<p className="text-center m-auto">
				Revisa aquí los Sibaritas mas destacados y sus votos.
				<br/>
				Recuerda que mientras mas votes puedes 
				<br/>
				subir de categoría y acceder a los beneficios.
				<br/>
				<Link
					to="/categorias"
					className="btn py-2 my-5 custom-btn-red custom-btn rounded-pill"
				>
					<GetCategoryUser/>
					Ver categorías
				</Link>
			</p>
      <SearchUser/>
			<div className="row px-2">
				<Judges/>
			</div>
			</div>
			<div
				className="col-12 d-flex justify-content-center fixed-bottom pb-5"
				style={{
					marginBottom: '5rem',
				}}
			>
				{
					pagination != null ?
					<Paginate 
						last_page={pagination.last_page}
						current_page={pagination.current_page} 
						per_page={pagination.per_page}
						loadList={this.loadList}
						classNamew="border border-bottom-0 bg-white px-5 pt-4"
					/>
					:null
				}
			</div>
			</div>
		);
	}
}