import React, { useState } from "react";
import style from "./css/FormAddLink.module.css";
import Api from "../Services/Api";

export default function FormAddLink({
  dataEvents,
  formAddVisible,
  setFormAddVisible,
}) {
  const dataScreen = [
    {
      name: "Perfil",
      value: "ProfileScreen",
    },
    {
      name: "Inicio",
      value: "HomeScreen",
    },
    {
      name: "Favoritos",
      value: "FavouriteScreen",
    },
  ];
  const [form, setForm] = useState({
    name: "",
    url: "",
    icon: "",
    type: "1",
    section: 1,
    visible: 1,
    event: 1,
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await Api.createLinkExternal(form);
    if (response) {
      setFormAddVisible(false);
      window.location.reload();
    } else {
      alert("Error al guardar");
    }
  };

  if (form.type === "1" && form.url === "") {
    form.url = "ScoreScreen";
  }

  return (
    <>
      {formAddVisible && (
        <div
          className={style.modalOverlay}
          onClick={() => setFormAddVisible(false)}
        >
          <div
            className={style.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className={style.close}
              onClick={() => setFormAddVisible(false)}
            >
              &times;
            </span>
            <h2>Agregar enlace</h2>
            <div className="form-group">
              <label htmlFor="type">Tipo</label>
              <select
                className="form-control"
                id="type"
                value={form.type}
                onChange={handleChange}
              >
                <option value={1}>Interno</option>
                <option value={2}>Externo</option>
                <option value={3}>Interno App</option>
              </select>
            </div>
            {form.type !== "1" && (
              <div className="form-group">
                <label htmlFor="url">URL</label>
                <input
                  value={form.url}
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="url"
                />
              </div>
            )}
            {form.type === "1" && (
              <div className="form-group">
                <label htmlFor="url">URL</label>
                <select
                  className="form-control"
                  id="url"
                  value={form.url}
                  onChange={handleChange}
                >
                  {dataScreen.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                value={form.name}
                onChange={handleChange}
                type="text"
                className="form-control"
                id="name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="icon">Icon</label>
              <input
                type="text"
                value={form.icon}
                onChange={handleChange}
                className="form-control"
                id="icon"
              />
            </div>
            <div className="form-group">
              <label htmlFor="section">Seccion</label>
              <select
                className="form-control"
                id="section"
                value={form.section}
                onChange={handleChange}
              >
                <option value={1}>Evento</option>
                <option value={2}>Menu</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="event">Evento</label>
              <select
                className="form-control"
                id="event"
                value={form.event}
                onChange={handleChange}
              >
                {dataEvents.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="visible">Estado</label>
              <select
                className="form-control"
                id="visible"
                value={form.status}
                onChange={handleChange}
              >
                <option value={1}>Activo</option>
                <option value={0}>Inactivo</option>
              </select>
            </div>

            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      )}
    </>
  );
}
