import  React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

//import placeholder_restaurant from '../Layout/Assets/placeholder-restaurant.jpg';

import chop from '../Layout/Assets/chop.gif';
import Api from '../Services/Api';

class Restaurant extends Component {
	constructor(props) {
		super(props)
		this.state = {
			slug: this.props.match.params.slug,
			restaurant: false,
			list_restaurant: false,
			loading:true,
			products:false,
		}
		this.getR = this.getR.bind(this)
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0,0)
		this.getR(this.state.slug)
	}
	UNSAFE_componentWillReceiveProps(nextProps){
		if(nextProps.match.params.slug !== this.props.match.params.slug){
			window.scrollTo(0,0)
			this.setState({
				slug:nextProps.match.params.slug,
				restaurant: false,
				list_restaurant: false,
				loading:true,
				products:false
			});
			this.getR(nextProps.match.params.slug)
		}
	}
	async getR(slug){
		let response = await Api.restaurant(slug)
		this.setState({
			restaurant:response,
			products: !!response.products.length ? response.products : null ,
			list_restaurant: !!response.list_restaurant.length ? response.list_restaurant : null ,
			loading:false
		})
	}
	render(){
		let {restaurant, loading, list_restaurant, products} = this.state

		return (
			<div className="row">
				{ products.length===1 ? <Redirect to={"/product/"+products[0].slug}/> : null}
				<div className="col-12 col-md-9 pt-5">
					<div className="row">
						<div className="col-3 col-md-3 px-1">
							{ typeof restaurant === 'object' ?
								<>
									<img alt="" className="img-fluid border" src={restaurant.logo_url}/>
								</>
							:
							<h3 className="text-center">
								Cargando foto...
							</h3>
							}
						</div>
						<div className="col-9 col-md-9 px-1">
							{ typeof restaurant === 'object' ?
								/*<>
									<p>
										<span className="text-danger h3">
											{restaurant.name}
										</span>
									</p>
									<hr/>
									<p>
										<strong>Dirección:</strong> 
										{restaurant.address}, {restaurant.commune.name}
									</p>
									<hr/>
									<p>
										<strong>Descripcion: </strong> 
										{restaurant.description}
									</p>
								</>*/
								<>
								<p>
									<span className="text-danger h3">
										{restaurant.name}
									</span>
									<hr/>
								</p>
								</>
							:
							<h3 className="text-center">
								Cargando Detalles del restaurante...
							</h3>
							}
						</div>
						<div className="col-12 col-md-12">
							<div className={"row "+(loading===true ? "justify-content-center" : "")}>
								<div className="col-12 px-1 pt-3">
									<h5>
										Productos
									</h5>
									<hr/>
								</div>
								{
									typeof products === 'object' ?
										products.map(function(p, i){
											return (
												<div className="col-12 col-md-12 col-lg-6 mb-4 px-1" key={'r-'+i}>
													<Link to={"/product/"+p.slug} className="h-100">
													<div className="row no-gutters text-dark d-flex align-items-stretch h-100">
														<div className="col-4">
															<img alt={"img-"+p.slug} className="card-img h-100" src={p.logo_url}/>
														</div>
														<div className="col-8">
															<div className="card-body">
																<div className="card-text">
																	<div
																		className="star-ratings d-none"
																		style={{ width: '100%'}}
																	>
																		<div
																			className="fill-ratings"
																			style={{ width: '100%'}}
																		>
																			<div>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																			</div>
																		</div>
																		<div
																			className="empty-ratings"
																			style={{left:'0px',position:'relative'}}
																		>
																			<div>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																				<i className="fa fa-star fa-lg"/>
																			</div>
																		</div>
																	</div>
																	<img 
																	alt=""
																	className="img-fluid"
																	src={restaurant.logo_url}
																	style={{
																		// "position": "absolute",
																		// "width": "50px",
																		// "left": "0px",
																		// "background":"white",
																		// "top": "0px",
																		// "border": "0px solid #dee2e6"

																		"position": "absolute",
																		"width": "65px",
																		"right": "6px",
																		"background":"white none repeat scroll 0% 0%",
																		"top": "6px",
																		"border": "1px solid  #dee2e6",
																		"borderRadius": "50%",
																		"padding": "5px",
																	}}/>
																	<small className="d-none">
																		{5}
																	</small>
																	<small className="d-none">votos: {p.total_votos}</small>
																</div>
																<h5 className="card-title pt-5 mt-3">
																	{p.name}
																</h5>
															</div>
														</div>
													</div>
														{/*<div className="border mb-5 text-dark h-100">
															<img alt="" className="img-fluid" src={p.logo_url}/>
															<h5 className="text-center">
															{p.name} #{p.id}
															</h5>
															<p className="px-4 text-center">
																<small></small>
															</p>
														</div>*/}
													</Link>
												</div>
											)
										})
									:
										<>
										{	loading===true ?
											<div className="col-md-4 text-center h4">
												<img alt="" className="img-fluid" src={chop}/>
												Cargando menu..
											</div>
											:
											<div className="col-12">
												<h3 className="text-center py-5">
													:(
													Sin productos
												</h3>
											</div>

										}
										</>
								}
							</div>
						</div>
					</div>
					{/*<div className="col-12 px-0" dangerouslySetInnerHTML={{__html: restaurant.description}} />*/}
				</div>
				<div className="col-12 col-md-3 pb-3 px-1 pt-5">
					<p 
						className="card-text"
						dangerouslySetInnerHTML={{__html: restaurant.description}}
						style={{whiteSpace:"pre-wrap"}}
					/>
					<div className="list-group">
						<li className="list-group-item bg-dark text-white">Mas Restaurantes</li>
						{
							typeof list_restaurant === 'object' ?
								list_restaurant.map(function(item, i){
									return (
									<Link to={"/restaurante/"+item.slug} 
									className="list-group-item text-dark" 
									key={'l-'+i}>
										{ 	restaurant.id === item.id 
											? 
											<i className="fa fa-caret-right fa-lg text-success"></i>
											: 
											'' 
										}
										&nbsp;{ item.name }

									</Link>
									)
								})
							:
								<>
								{	loading===true ?
									<li className="list-group-item">Cargando lista</li>
									:
									<li className="list-group-item">Sin Restaurantes</li>

								}
								</>
						}
					</div>
				</div>
			</div>
		);
	}
}
export default Restaurant;