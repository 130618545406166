import React from 'react';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';

const SearchUser = () => {
  const history = useHistory();
	const query = QueryString.parse(history.location.search);

  return(
    <div className="row">
      <div className="col-12">
        <input 
          type="text"
          className="form-control my-2"
          name="input-search"
          placeholder="Buscar juez.."
          aria-label="Buscar"
          aria-describedby="basic-addon1"
          defaultValue={null}
          onChange={(e)=>{
            query['search_user'] = e.target.value;
            history.push({
              pathname: history.location.pathname,
              search:QueryString.stringify(query),
              state: { ['search_user']: e.target.value }
            })
          }}
        />
      </div>
    </div>
  )
}

export default SearchUser;